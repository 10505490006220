:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

html,
body {
  height: 100%;
}

* {
  font-family: 'Rubik', Helvetica, Arial, serif;
}

body {
  background: #f0f4f7;
  color: $bodyTextColor;
}

input {
  font-size: $inputFontSize;
}

// h4 {
//   color: $secondary;
// }

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: $tertiaryText;
}

.swal2-styled.swal2-confirm {
  box-sizing: border-box;
  background: $primary !important;
  color: $primaryText !important;
  font-size: $btnFontSize !important;
  border: 1px solid $primary !important;
  border-radius: $btnBorderRadius !important;
  position: relative; /* For positioning the pseudo-element */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;

  &:hover {
    color: $primaryText;
    border: 1px solid $primary;
  }

  &:before {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    /* Hidden by default. */
    opacity: 0;
    transition: opacity 500ms;
  }

  &:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
  }
}

.form-group {
  small {
    color: $invalidText !important;
  }

  .custom-switch {
    z-index: unset;

    .custom-control-label::before {
      background-color: #e7edf3;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $primary;
    }

    .custom-control-label::after {
      background-color: #fff;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .bx-help-circle {
    font-size: unset;
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $primary !important;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff !important;
}

.daterangepicker .drp-buttons .btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.loading-white-bg {
  text-align: center;
  .lds-ellipsis div {
    background: $primary;
  }
}

table {
  color: #727e8c;
  border-top: none;
  vertical-align: middle;
  font-size: 0.8rem;
  letter-spacing: 1px;

  thead {
    color: #475f7b;
    border-top: none;
    vertical-align: middle;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  tbody tr td i {
    color: $invalidText;
    cursor: pointer;
  }

  tbody tr td img {
    width: 13%;
    margin-top: 2em;
  }
}

.bx {
  font-size: 1.3em;
}

.tooltip {
  .tooltip-inner {
    background-color: $tertiaryText;
    border-color: $tertiaryText;
  }

  .arrow::before {
    border-top-color: $tertiaryText;
  }
}

.fallback-img-container {
  text-align: center;

  img {
    width: 50%;
    padding-top: 1em;
  }
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 0.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

// All pages heading
.page-heading {
  font-size: $heaidngfontSize;
  font-weight: $boldFontWeight;
}

// Input placeholder
::placeholder {
  font-size: $mdFontSize;
}

label {
  font-size: $labelFontSize;
  letter-spacing: 0.24px;
  color: $secondary;
}

input {
  border-radius: $inputBorderRadius;
}

.bg_dark--primary {
  background-color: $primaryDark;
}

.bg_dark--secondary {
  background-color: $seondaryDark;
}

#custom-spinner {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 60px;
    height: 60px;
    border: 4px solid #ece9e9;
    border-top: 6px solid #f25a41;
    border-radius: 100%;
    will-change: transform;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

#notification-wrapper .toast-notification {
  z-index: 9999 !important;
}

.disabledLink,
.disabledBtn {
  cursor: not-allowed;
}

/*
*  Scrollbar Color and Size
*/

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.86, $inActive)
  );
}
