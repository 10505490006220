.Reports {
  .result-filter {
    @include greaterThan($screen-sm) {
      margin-top: 1.9em;
    }
  }

  table thead tr {
    th:nth-child(3) {
      min-width: 137px;
    }

    th:nth-child(7) {
      max-width: 413px;
      overflow-wrap: break-word;
    }
  }

  table tbody tr td:nth-child(7) {
    max-width: 413px;
    overflow-wrap: break-word;
  }

  .badge-wrapper {
    text-transform: uppercase;

    .badge {
      font-size: unset;
      font-weight: unset;
    }

    .badge-light-success {
      background-color: #d2ffe8;
      color: #39da8a;
    }

    .badge-light-primary {
      background-color: #e2ecff;
      color: #f58025;
    }

    .badge-light-warning {
      background-color: #ffeed9;
      color: #fdac41;
    }

    .badge-light-danger {
      background-color: #ffdede;
      color: #ff5b5c;
    }
  }

  // .summary-data {
  //   text-align: center;
  // }
}
