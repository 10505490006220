.Developer {
  span {
    font-weight: 400;
    line-height: 1.2;
    color: $tertiaryText;
  }

  button {
    min-width: 81px;

    span {
      color: #fff;
    }
  }

  table {
    color: #727e8c;
  }

  .whitelist {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      flex: 70%;
      color: #727e8c;
      font-size: 0.8rem;
      letter-spacing: 1px;
    }

    i {
      flex: 30%;
      font-size: 1.5em;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    .bx-trash {
      color: #ff5b5c;
    }

    .bx-timer {
      color: #fdac41;
    }
  }

  .documentation {
    label {
      display: block;
      text-transform: initial !important;
    }

    ol {
      li {
        color: $tertiaryText;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }

    code {
      padding: 0.1rem 0.4rem;
      background-color: #eee;
      border-radius: 0.1335rem;
    }

    pre {
      background-color: #f7f7f9;
    }
  }
}
