.pagination-custom-style {
  .pagination {
    justify-content: flex-end;
  }

  .active-page {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: $primary;
    border-radius: 50px;
    text-align-last: center;
  }

  .active-link {
    color: white !important;
    text-decoration: none;
  }

  li {
    padding: 0.4em 0.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }

  .defaul-link {
    color: #999999;
    text-decoration: none;
    font-size: 0.7em;
  }
}
