@import url('https://fonts.googleapis.com/css?family=Open+Sans:600');

.custom-radio {
  .showOverlay {
    bottom: 0;
    display: flex;
    font-size: 16px;
    left: calc(50% - 55px);
    margin-bottom: 7px;
    position: absolute;
  }

  .check {
    bottom: 0;
    display: flex;
    left: calc(50% + 40px);
    margin-bottom: 10px;
    position: absolute;
  }

  .container {
    position: relative;
    margin: 20px;
    overflow: hidden;
    padding: 0;
  }

  .hidden {
    display: none;
  }

  .entry {
    height: 25px;
    position: absolute;
    width: 100%;
    padding-right: 12px;
  }

  .entry:nth-child(2) {
    left: 10px;
    top: 8px;
  }

  .entry:nth-child(4) {
    left: 10px;
    top: 58px;
  }

  .entry:nth-child(6) {
    left: 8px;
    top: 108px;
  }

  .circle {
    border: 2px solid #545556;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    transition: border-color 300ms;
    height: 16px;
    width: 16px;
  }

  .entry-label {
    cursor: pointer;
    margin-top: -3px;
    padding-left: 30px;
    user-select: none;
    -moz-user-select: none;
  }

  .entry-label select,
  .entry-label input {
    font-size: 0.9em;
    height: 44px;
  }

  .overlay {
    background: #fff;
    mask: url(#holes);
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAACMCAYAAAAOc+uVAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAItSURBVHic7ZnNjhJBFIVPA0txr0Iyw+tIdOXWaDRufZWZeYbZuMKOUV9Gg+HHGN/BHBcNcSBT1KVOEVycb0mq635UVd9U5zQkif+Y3rkFclhQxYIqFlQZiM8vALQAPgP4AWC1+X0E4ALAFMBzAOPiCixjRfIdyX5g/h7JFyTnJYVKBGckHxQsxJBke2rBK5LF55bdal6fSnCmyO1JhlcyKrhkwbYekBySXNcUfF1L7o7k20jhhsxetxYAJk3T/Kks2EfXmkaHxkXO1MfacgCwmbPNjYsIftV1knzJDYgIfqsgkuJ7bkBE8FcFkRTr3IBzXxay9SOCjyuIFM8dEZxUECmeOyL4tIJIimluQKRRLwFcnqBRDwDMUaFRjwG8quC0zxtk5ACEL6wrksNaZiQfkvwZKRxtM08AfGDgBh2Q6wG4BfAo+sAxXB/xp+6VI3lzTMGSK3/Lgu1mt62fji1W+tH0m+R7dm9iZNVeMnjm9om0mUOs8O+zc47dz84Juh76DJG3NYEqeHLOfVnIYkEVC6pYUMWCKhZUsaCKBVUsqGJBFQuqWFDFgipO3OnEfRcn7qWCTtxTOHFP4cS9Ek7cVZy4qzhxL4ZO3AXoxF2QoxP3DifuW5y4q1hQxYIqFlSxoIoFVSyoYkEVC6pYUMWCKhZUsaCKE3c6cd/FiXupoBP3FE7cUzhxr4QTdxUn7ipO3IuhE3cBOnEX5OjEvcOJ+xYn7ioWVLGgigVV/gKEL4RSyM8Z8wAAAABJRU5ErkJggg==);
    height: 140px;
    pointer-events: none;
    transition: background 300ms;
    width: 40px;
  }

  .highlight {
    background: $secondary;
    border-radius: 50%;
    height: 12px;
    left: 14px;
    pointer-events: none;
    position: absolute;
    top: 16px;
    transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
    transform: translateY(-50px);
    width: 12px;
  }

  .hidden:nth-child(1):checked ~ .highlight {
    transform: translateY(0);
  }

  .hidden:nth-child(3):checked ~ .highlight {
    transform: translateY(50px);
  }

  .hidden:nth-child(5):checked ~ .highlight {
    transform: translateY(100px);
  }

  .hidden:nth-child(1):checked + .entry .circle {
    border-color: $secondary;
  }

  .hidden:nth-child(3):checked + .entry .circle {
    border-color: $secondary;
  }

  .hidden:nth-child(5):checked + .entry .circle {
    border-color: $secondary;
  }

  .check:checked ~ .containerOuter .overlay {
    background: #ecf0f4;
  }
}

// New radio button

.form-check {
  margin: 0.5rem;
}

.form-check input[type='radio'] {
  position: absolute;
  opacity: 0;
}

.form-check input[type='radio'] + .form-check-label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.form-check input[type='radio']:checked + .form-check-label:before {
  background-color: $primary !important;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}

.form-check input[type='radio']:focus + .form-check-label:before {
  outline: none;
  border-color: $primary;
}

.form-check input[type='radio']:disabled + .form-check-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.form-check input[type='radio'] + .form-check-label:empty:before {
  margin-right: 0;
}

// .form-check {
//   input
// }
