.TitleAndCloseHeader {
  i {
    position: absolute;
    right: 20px;
    top: 20px;
    color: $tertiaryText;
    font-size: 1.2em;
    cursor: pointer;
  }
}
