.ForgotPassword {
  .modal-body {
    label {
      color: $tertiaryText;
      font-size: $labelFontSize;
      font-weight: normal;
    }

    small {
      color: $invalidText;
    }
  }
}
