.AdminTools {
  .select-admin {
    margin-top: 1.9em;
  }

  .UserManagement {
    .user-email {
      @include greaterThan($screen-sm) {
        margin-top: 1.9em;
      }
    }

    .user-management-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-right: 20px;
      }
    }

    .user-management-action {
      .bx {
        font-size: 1.2rem;
      }

      .bx-search-alt-2 {
        color: #39da8a;
      }

      .bx-lock {
        color: #ff5b5c;
      }

      .bx-lock-open {
        color: #39da8a;
      }

      .bxs-key {
        color: #39da8a;
      }

      .bxs-key.admin {
        color: #828d99;
      }
    }
  }

  .AdminReport {
    .short-code {
      @include greaterThan($screen-sm) {
        margin-top: 1.9em;
      }
    }

    table thead tr th:nth-child(6) {
      max-width: 440px;
      overflow-wrap: break-word;
    }

    table tbody tr td:nth-child(6) {
      max-width: 440px;
      overflow-wrap: break-word;
    }

    .fallback-img-container {
      img {
        width: 20%;
      }
    }
  }

  .badge-wrapper {
    text-transform: uppercase;

    .badge {
      font-size: unset;
      font-weight: unset;
    }

    .badge-light-success {
      background-color: #d2ffe8;
      color: #39da8a;
    }

    .badge-light-primary {
      background-color: #e2ecff;
      color: #f58025;
    }

    .badge-light-warning {
      background-color: #ffeed9;
      color: #fdac41;
    }

    .badge-light-danger {
      background-color: #ffdede;
      color: #ff5b5c;
    }
  }
}
